:root {
  --textColor: #777777;
  --linkColor: #577A63;
  --linkColorHover: #B1B1B1;
  --headerHeight: 128px;
  --timelineUnitWidth: 540px;
}
@media (max-width: 1023px) {
  :root {
    --headerHeight: 80px;
    --timelineUnitWidth: 340px;
  }
}
:root {
  --deskWidth: 1106px;
  --deskSpace: 35px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root {
  --spacePart: 12px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 8px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 8px;
  }
}
:root {
  --spaceUnit: 28px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 17px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 17px;
  }
}
:root {
  --spaceTotal: 40px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 25px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.title1 {
  font-size: 48px;
  line-height: 1;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title1 {
    font-size: 36px;
    line-height: 1.11111111;
  }
}
.title2 {
  font-size: 36px;
  line-height: 1.11111111;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title2 {
    font-size: 24px;
    line-height: 1.33333333;
  }
}
.title3 {
  font-size: 30px;
  line-height: 1.2;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title3 {
    font-size: 20px;
    line-height: 1.4;
  }
}
.title4 {
  font-size: 24px;
  line-height: 1.33333333;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .title4 {
    font-size: 20px;
    line-height: 1.4;
  }
}
.title5 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 300;
  color: var(--textColor);
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.buttonBasics {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
}
.buttonBasics:hover,
.buttonBasics:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.button {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.button:hover,
.button:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.button:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .button {
    width: 100%;
  }
}
.button2 {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 17px;
}
.button2:hover,
.button2:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.buttonDownload {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.buttonDownload:hover,
.buttonDownload:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.buttonDownload:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .buttonDownload {
    width: 100%;
  }
}
.buttonDownload:before {
  mask-image: url(/images/arrow-bottom.svg);
}
/* inter-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('/extras/fonts/inter-v18-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/inter-v18-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('/extras/fonts/inter-v18-latin-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/inter-v18-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
}
*::selection {
  background: #577A63;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
}
.unit caption {
  display: none;
}
.flag {
  background: #577A63;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  position: relative;
}
.area .unit {
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  position: relative;
}
.unit .part {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.lazyimage {
  visibility: hidden;
}
.lazyimage.lazyloaded {
  visibility: initial;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--textColor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
#expo:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 40%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent);
  pointer-events: none;
  z-index: 2;
}
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  overflow: hidden;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#slides img.lazyimage {
  visibility: initial;
  filter: blur(10px);
  transform: scale(1.05);
  transition: all 0.12s;
}
#slides img.lazyimage.lazyloaded {
  filter: blur(0);
  transform: scale(1);
}
#expo div.link {
  display: block;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  mask-size: 7px 13px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: #fff;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
@media (max-width: 767px) {
  #expo a.link {
    top: unset;
    transform: translateY(0);
    bottom: 100px;
  }
}
#expo a.link.prev {
  left: 20px;
  mask-image: url(/images/chevron-left.svg);
}
#expo a.link.next {
  right: 20px;
  mask-image: url(/images/chevron-right.svg);
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 767px) {
  .cb-index-all {
    bottom: 108px;
  }
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 8px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 10px;
  height: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #EAEAEA;
  border-radius: 10px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #577A63;
}
.cb-index-some {
  display: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  line-height: 1.42857143;
  color: var(--textColor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #577A63;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #577A63;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
#disp .foot input:before,
#disp .foot a:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input[type*="radio"],
#disp input[type*="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Inter', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 42px;
    padding-top: 9px;
    padding-bottom: 9px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  min-height: 42px;
  padding: 5px 0;
  appearance: none;
  border-bottom: 1px solid #EAEAEA;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 9px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background: #fff;
  height: 42px;
  border: 1px solid var(--textColor);
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop span::file-selector-button {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
  margin-right: 16px;
}
.unit.form .ship div.chop span::file-selector-button:hover,
.unit.form .ship div.chop span::file-selector-button:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.unit.form .ship div.chop span::file-selector-button:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .unit.form .ship div.chop span::file-selector-button {
    width: 100%;
  }
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 42px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: left;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.unit.form .submit:hover,
.unit.form .submit:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.unit.form .submit:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .unit.form .submit {
    width: 100%;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--textColor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
  text-align: left;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.two-step-verification-container a:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .two-step-verification-container a {
    width: 100%;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.08604954vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
main {
  float: left;
  width: 100%;
  flex: 1 0;
}
#home {
  float: left;
  height: 88px;
}
@media (max-width: 1023px) {
  #home {
    height: 64px;
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
}
.layout2 #head,
.layout5 #head {
  margin-top: var(--spaceTotal);
}
.layout3 #head,
.layout6 #head {
  margin-top: calc(var(--spaceTotal) * 2);
}
.layout2 #head,
.layout3 #head,
.layout5 #head,
.layout6 #head {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layout2 #head h1,
.layout3 #head h1,
.layout5 #head h1,
.layout6 #head h1 {
  float: left;
  width: 100%;
  max-width: 916px;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--textColor);
  accent-color: #577A63;
  overflow: hidden;
  hyphens: none;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  padding-top: var(--headerHeight);
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  background-color: #fff;
  --deskWidth: 1280px;
}
.container--head {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.section--heroSlider {
  --deskWidth: 1280px;
  --textColor: #fff;
  --linkColor: #fff;
  --linkColorHover: #577A63;
}
.section--multimood {
  --deskWidth: 1280px;
  --textColor: #fff;
  --linkColor: #fff;
  --linkColorHover: #577A63;
}
.container--mood {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100svh;
  padding: 50px 0;
  isolation: isolate;
  box-sizing: border-box;
}
.layout1 .container--mood {
  max-height: 1000px;
}
body:not(.layout1) .container--mood {
  max-height: 750px;
}
.mood {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.content {
  float: left;
  width: 100%;
  max-width: 530px;
}
.cbdModule--moodText {
  margin-top: 16px;
}
.cbdModule--moodText .moodText {
  color: var(--textColor);
}
.cbdModule--moodLink {
  margin-top: 32px;
}
.cbdModule--moodLink .moodLink {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
  border: none;
}
.cbdModule--moodLink .moodLink:hover,
.cbdModule--moodLink .moodLink:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.cbdModule--moodLink .moodLink:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .cbdModule--moodLink .moodLink {
    width: 100%;
  }
}
#view .section--newsTeaserRecent:not(:has(.ns-teaser)) {
  display: none;
}
.container--newsTeaser {
  float: left;
  width: 100%;
  margin: var(--spaceTotal) 0;
}
.container--newsTeaserRecent {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--spaceTotal) 0;
}
.layout1 .container--newsTeaserRecent {
  margin-top: calc(var(--spaceTotal) * 2);
}
.newsTeaser__section {
  float: left;
  width: 100%;
}
.newsTeaser__section--title {
  max-width: 538px;
  text-align: center;
}
.newsTeaser__section--content {
  margin-top: calc(var(--spaceTotal) + var(--spacePart));
}
.newsTeaser__section--action {
  text-align: center;
  margin-top: var(--spaceUnit);
}
.cbdModule--newsTeaserRecentText {
  margin-top: calc(var(--spacePart) * 2);
}
.newsTeaserLink {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 17px;
}
.newsTeaserLink:hover,
.newsTeaserLink:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.backlink {
  float: left;
  text-decoration: underline;
  margin-bottom: var(--spacePart);
}
#eapps-instagram-feed-1 {
  width: 100% !important;
}
#edit .section--footer {
  padding-bottom: 40px;
}
@media (max-width: 1023px) {
  .section--footer {
    text-align: center;
  }
}
.footarea {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: var(--spaceTotal);
}
.footpart {
  float: left;
  display: flex;
  flex-direction: column;
}
.footlogo {
  float: left;
  height: 61px;
}
.cbdModule--footText {
  margin-top: 18px;
}
.newsletter {
  float: left;
  width: 100%;
  margin-top: 16px;
}
.newsletter #mc_embed_signup_scroll {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 5px 5px 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #F1F3F7;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
@media (max-width: 767px) {
  .newsletter #mc_embed_signup_scroll {
    padding: 0;
    flex-direction: column;
    gap: 8px;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}
.newsletter .mc-field-group {
  float: left;
  width: calc(100% - 120px);
}
.newsletter .mc-field-group input {
  text-align: left;
  color: var(--textColor);
}
.newsletter .mc-field-group input::placeholder {
  color: #B1B1B1;
}
@media (max-width: 767px) {
  .newsletter .mc-field-group {
    width: 100%;
  }
  .newsletter .mc-field-group input {
    width: 100%;
    box-sizing: border-box;
    padding: 14px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #F1F3F7;
    box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
  }
}
.newsletter .clear {
  float: left;
}
@media (max-width: 767px) {
  .newsletter .clear {
    width: 100%;
  }
}
.newsletter #mc-embedded-subscribe {
  color: #fff;
  background-color: #577A63;
  padding: 9px 17px;
  border: none;
}
.newsletter #mc-embedded-subscribe:hover,
.newsletter #mc-embedded-subscribe:focus {
  background-color: #B1B1B1;
}
@media (max-width: 767px) {
  .newsletter #mc-embedded-subscribe {
    width: 100%;
  }
}
.unit--footLinks {
  --spacePart: 0;
}
#edit .unit--footLinks {
  outline-color: #71caf3;
  min-height: 20px;
}
.unit--footLinks .unit__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.unit--footLinks .part {
  width: auto;
  font-size: 12px;
  line-height: 1.5;
  --linkColor: #CBCBCB;
  --linkColorHover: #577A63;
}
.unit--footLinks .part:not(:last-child):after {
  content: '|';
  margin: 0 4px;
  color: #CBCBCB;
}
@media (max-width: 1023px) {
  .unit--footLinks .unit__body {
    justify-content: center;
  }
  .unit--footLinks .part {
    font-size: 14px;
    line-height: 1.57142857;
    --linkColor: #B1B1B1;
  }
  .unit--footLinks .part .open {
    text-decoration: underline;
  }
}
.footTitle {
  color: var(--textColor);
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 300;
}
.copyright {
  color: #CBCBCB;
  font-size: 12px;
  line-height: 1.5;
}
.part--socialLinks {
  margin-top: 20px;
}
.part--socialLinks .iconLinks__content {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
@media (max-width: 1023px) {
  .part--socialLinks .iconLinks__content {
    justify-content: center;
  }
}
.part--socialLinks .iconLinks__icon {
  float: left;
  width: 24px;
  height: 24px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: #577A63;
  transition: all 0.4s;
}
.part--socialLinks .iconLinks__icon--facebook {
  mask-image: url(/images/icon-facebook.svg);
}
.part--socialLinks .iconLinks__icon--instagram {
  mask-image: url(/images/icon-instagram.svg);
}
.part--socialLinks .iconLinks__icon--linkedin {
  mask-image: url(/images/icon-linkedin.svg);
}
.part--socialLinks .iconLinks__icon:hover,
.part--socialLinks .iconLinks__icon:focus {
  background-color: #B1B1B1;
}
#cb-cookie-warning {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10;
  transition: all 0.5s 0.5s cubic-bezier(0, 0.61, 0.17, 1.3);
  transform: translateY(200%);
}
.show-content #cb-cookie-warning {
  margin-right: 70px;
  margin-bottom: 70px;
  transform: translateY(0);
}
@media (max-width: 1023px) {
  .show-content #cb-cookie-warning {
    margin-right: 2vw;
    margin-bottom: 2vw;
  }
}
.cb-cookie-warning--container {
  float: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #577A63;
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.57142857;
  border-radius: 10px;
  transition: all 0.5s 0.5s cubic-bezier(0, 0.61, 0.17, 1.5);
  padding: 20px 30px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--container {
    width: 96vw;
    max-width: 96vw;
    align-items: flex-start;
  }
}
.cb-cookie-warning--text {
  float: left;
  text-align: center;
  padding-top: 80px;
  box-sizing: border-box;
  width: 100%;
  background-size: 46px 46px;
  background-position: 50% 10px;
  background-repeat: no-repeat;
  color: #FFF;
  background-image: url(/images/icon-cookie-white.svg);
}
@media (max-width: 767px) {
  .cb-cookie-warning--text {
    text-align: left;
    padding-top: 0;
    padding-left: 80px;
    background-position: 0 50%;
    background-size: 39px 39px;
  }
}
.cb-cookie-warning--actions {
  float: left;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--actions {
    margin-top: 10px;
  }
}
.cb-cookie-warning__button {
  float: left;
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.cb-cookie-warning__button:hover,
.cb-cookie-warning__button:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.cb-cookie-warning__button:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .cb-cookie-warning__button {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .cb-cookie-warning__button {
    margin-left: 80px;
  }
}
#cb-cookie-warning__button--decline {
  display: none;
}
a {
  color: var(--linkColor);
  text-decoration: none;
}
a:hover,
a:focus {
  color: var(--linkColorHover);
}
h1,
h2 {
  font-size: 48px;
  line-height: 1;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  h1,
  h2 {
    font-size: 36px;
    line-height: 1.11111111;
  }
}
.layout3 h1 {
  text-align: center;
}
h3 {
  font-size: 36px;
  line-height: 1.11111111;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  h3 {
    font-size: 24px;
    line-height: 1.33333333;
  }
}
h4 {
  font-size: 30px;
  line-height: 1.2;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  h4 {
    font-size: 20px;
    line-height: 1.4;
  }
}
h5 {
  font-size: 24px;
  line-height: 1.33333333;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  h5 {
    font-size: 20px;
    line-height: 1.4;
  }
}
.timeline h5 {
  --textColor: #577A63;
}
h6 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 300;
  color: var(--textColor);
}
.norm {
  color: var(--textColor);
}
.pale {
  font-size: 14px;
  line-height: 1.57142857;
  color: var(--textColor);
}
.loud {
  font-size: 18px;
  line-height: 1.55555556;
  color: var(--textColor);
}
.skew {
  font-size: 72px;
  line-height: 1;
  font-weight: bold;
  color: #9AC3B5;
}
@media (max-width: 1023px) {
  .skew {
    font-size: 60px;
    line-height: 1;
  }
}
.area--one {
  display: flex;
  flex-wrap: wrap;
  gap: 0 22px;
}
.area--one .unitOne {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area--one .unitOne--1-1-smallIndent .unit__content {
  max-width: 916px;
}
.area--one .unitOne--1-1-largeIndent .unit__content {
  max-width: 538px;
}
.area--one .unitOne .unit__body {
  display: flex;
  flex-wrap: wrap;
  gap: 0 22px;
}
.area--one .unitOne .part--small {
  width: calc(50% - 11px);
}
.area--one .unitTwo {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  display: flex;
  flex-wrap: wrap;
}
.area--one .unitTwo .unit__background {
  overflow: hidden;
  border-radius: 8px;
}
.area--one .unitTwo .unit__body {
  display: flex;
  flex-wrap: wrap;
  gap: 0 22px;
}
.area--one .unitTwo .part--small {
  width: calc(50% - 11px);
}
.area--one .unitThree {
  box-sizing: border-box;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
}
.area--one .unitFold {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  box-sizing: border-box;
  border-bottom: 1px solid #EAEAEA;
}
.area--one .unitFold + .unitFold {
  margin-top: calc((var(--spaceTotal) - 1px) * -1);
}
.area--one .unitFold .unit__content {
  max-width: 916px;
}
.area--one .unitFold div.more {
  display: block !important;
  margin-top: calc(var(--spacePart) * -1);
}
#view .area--one .unitFold div.more {
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
#view .area--one .unitFold div.more .part {
  opacity: 0;
  transform: translateY(-30px);
  transition: all 0.4s;
}
.area--one .unitFold .fold-toggle {
  float: left;
  width: 100%;
  margin: 4px 0;
  color: var(--textColor);
  position: relative;
}
.area--one .unitFold .fold-toggle:before {
  content: '';
  position: absolute;
  right: 0;
  top: 5px;
  width: 13px;
  height: 13px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-plus.svg);
  background-color: #9AC3B5;
  transition: all 0.6s;
}
.area--one .unitFold .fold-toggle--open:before {
  transform: rotate(45deg);
  background-color: #577A63;
}
#view .area--one .unitFold.unit--foldOpen div.more {
  height: var(--js-elementHeight);
}
#view .area--one .unitFold.unit--foldOpen div.more .part {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.2s;
}
.area--one .unitFold .unit__foot {
  display: none;
}
.area--one .unit--form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area--one .unit--form .unit__content {
  max-width: 916px;
}
.area--one .unitGallery {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  --spacePart: 0;
}
.area--one .unitGallery .unit__body {
  display: grid;
}
.area--one .unitGallery .part.pict {
  overflow: hidden;
}
.area--one .unitGallery .part.pict * {
  height: 100%;
}
.area--one .unitGallery .part.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitGallery .part.pict .placeholder {
  padding-bottom: 0;
}
.area--one .unitGallery--styleOne {
  flex-shrink: 0;
  width: clamp(calc(100% + (var(--deskSpace) * 2)), calc(100% + ((100vw - 1176px) / 2)), calc(100% + 114px));
  left: 50%;
  transform: translateX(-50%);
}
.area--one .unitGallery--styleOne .part.pict {
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(25, 33, 61, 0.11);
}
.unit--alignCenter {
  text-align: center;
}
.unit--alignRight {
  text-align: right;
}
.part--alignCenter {
  text-align: center;
}
.part--alignRight {
  text-align: right;
}
.linkStyle--one .open {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.linkStyle--one .open:hover,
.linkStyle--one .open:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.linkStyle--one .open:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .linkStyle--one .open {
    width: 100%;
  }
}
.linkStyle--two .open {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 17px;
}
.linkStyle--two .open:hover,
.linkStyle--two .open:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.part.load:not(.pict) .load {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 32px 9px 17px;
  box-shadow: 0 1px 4px rgba(25, 33, 61, 0.08);
}
.part.load:not(.pict) .load:hover,
.part.load:not(.pict) .load:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.part.load:not(.pict) .load:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: #577A63;
}
@media (max-width: 767px) {
  .part.load:not(.pict) .load {
    width: 100%;
  }
}
.part.load:not(.pict) .load:before {
  mask-image: url(/images/arrow-bottom.svg);
}
.iconText {
  box-sizing: border-box;
  padding: 2px 0 2px 64px;
  --linkColor: var(--textColor);
  --linkColorHover: #577A63;
}
.iconText:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: var(--textColor);
}
.iconText--mail:before {
  mask-image: url(/images/icon-mail.svg);
}
.iconText--phone:before {
  mask-image: url(/images/icon-phone.svg);
}
@media (max-width: 1023px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .section {
    transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  body.cb-toggle-target-active .section {
    transform: translateY(200px);
  }
  body.cb-toggle-target-active .section.section--header {
    transform: none;
  }
  .navi {
    display: none;
  }
}
.togglenavigation {
  position: relative;
  z-index: 2001;
  width: 22px;
  height: 20px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 2px;
  background-color: #577A63;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #B1B1B1;
}
.mobile-navigation div.navi > .item > .menu.path {
  font-weight: bold;
  color: #577A63;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
  margin-top: 126px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.mobile-navigation div.sub1 > .item > .menu {
  font-size: 24px;
  line-height: 1.25;
  text-decoration: none;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub1 {
    margin-top: 114px;
    gap: 8px;
  }
  .mobile-navigation div.sub1 > .item > .menu {
    font-size: 16px;
    line-height: 1.5;
  }
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 22px;
  height: 24px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.container--head {
  margin: 8px 0;
  align-items: center;
}
.footarea {
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 30px;
  border-top: 1px solid #EAEAEA;
}
.footpart {
  width: 100%;
  align-items: center;
}
.footpart--two {
  margin-top: 48px;
}
.footpart--three {
  margin-top: 48px;
}
.footpart--four {
  margin-top: 48px;
  padding-top: 22px;
  border-top: 1px solid #EAEAEA;
}
.area--one .unitTwo {
  gap: var(--var(--spacePart));
}
.area--one .unitThree {
  min-height: 153px;
  padding: 23px;
}
.area--one .unitGallery--styleOne .unit__body {
  grid-template-columns: repeat(4, 1fr);
}
.area--one .unitGallery--styleOne .part.pict {
  width: calc(110% + var(--deskSpace));
  grid-column: span 2;
  grid-row: span 5;
  aspect-ratio: 1;
}
@supports not (aspect-ratio: 1 /  1) {
  .area--one .unitGallery--styleOne .part.pict:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  .area--one .unitGallery--styleOne .part.pict:after {
    display: block;
    content: '';
    clear: both;
  }
}
.area--one .unitGallery--styleOne .part.pict:nth-child(2n + 1) {
  margin-top: 11px;
  grid-column: 1 / span 2;
}
.area--one .unitGallery--styleOne .part.pict:nth-child(2n + 2) {
  grid-column: 3 / span 2;
  margin-left: calc(-10% - var(--deskSpace));
}
.area--one .unitGallery--styleOne .part.pict:nth-child(1) {
  margin-top: 120px !important;
}
.area--one .unitGallery--styleOne .part.pict:nth-child(2n + 4) {
  margin-top: -109px;
}
.area--one .unitGallery--styleTwo .unit__body {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 11px;
  grid-auto-flow: dense;
}
.area--one .unitGallery--styleTwo .part.pict {
  border-radius: 3px;
}
@media (max-width: 767px) {
  .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 1) {
    aspect-ratio: 1;
    grid-column: 1 / span 2;
    grid-row: span 3;
  }
  @supports not (aspect-ratio: 1 /  1) {
    .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 1):before {
      float: left;
      padding-top: 100%;
      content: '';
    }
    .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 1):after {
      display: block;
      content: '';
      clear: both;
    }
  }
  .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 2) {
    aspect-ratio: 0.75;
    grid-column: 3 / span 2;
    grid-row: span 4;
  }
  @supports not (aspect-ratio: 3 /  4) {
    .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 2):before {
      float: left;
      padding-top: 133.33333333%;
      content: '';
    }
    .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 2):after {
      display: block;
      content: '';
      clear: both;
    }
  }
  .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 3) {
    aspect-ratio: 1;
    grid-column: 1 / span 2;
    grid-row: span 3;
  }
  @supports not (aspect-ratio: 1 /  1) {
    .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 3):before {
      float: left;
      padding-top: 100%;
      content: '';
    }
    .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 3):after {
      display: block;
      content: '';
      clear: both;
    }
  }
  .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 4) {
    aspect-ratio: 1.5;
    grid-column: 3 / span 2;
    grid-row: span 2;
  }
  @supports not (aspect-ratio: 3 /  2) {
    .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 4):before {
      float: left;
      padding-top: 66.66666667%;
      content: '';
    }
    .area--one .unitGallery--styleTwo .part.pict:nth-child(4n + 4):after {
      display: block;
      content: '';
      clear: both;
    }
  }
}
.ns-teaserWrapper {
  float: left;
  width: 100%;
  position: relative;
}
.ns-teaserContent {
  float: left;
  width: 100%;
}
.ns-teaserArea {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
}
.ns-teaser {
  float: left;
  width: calc((100% / 3) - (44px / 3));
  box-shadow: 0 5px 15px rgba(25, 33, 61, 0.11);
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .ns-teaser {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .layout3 .ns-teaser:first-child.ns-teaser {
    width: 100%;
    margin-bottom: 40px;
  }
}
.ns-teaser__wrapper {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .layout3 .ns-teaser:first-child .ns-teaser__wrapper {
    flex-direction: row;
  }
}
.ns-teaser__content {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 32px 23px;
  border-top: 0;
  border-bottom: 1px solid #EAEAEA;
  border-left: 1px solid #EAEAEA;
  border-right: 1px solid #EAEAEA;
  border-radius: 0 0 8px 8px;
}
@media (min-width: 768px) {
  .layout3 .ns-teaser:first-child .ns-teaser__content {
    justify-content: center;
    width: 53%;
    border-radius: 0 8px 8px 0;
    padding: 0 48px;
    border-top: 1px solid #EAEAEA;
    border-left: 0;
  }
}
.ns-teaser__part {
  float: left;
  width: 100%;
}
.ns-teaser__part--image {
  aspect-ratio: 1.33333333;
  overflow: hidden;
}
.ns-teaser__part--image * {
  height: 100%;
}
.ns-teaser__part--image img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.ns-teaser__part--image .placeholder {
  padding-bottom: 0;
}
@supports not (aspect-ratio: 4 /  3) {
  .ns-teaser__part--image:before {
    float: left;
    padding-top: 75%;
    content: '';
  }
  .ns-teaser__part--image:after {
    display: block;
    content: '';
    clear: both;
  }
}
.ns-teaser__part--image .ns-teaserImage {
  transition: all 0.24s;
}
.ns-teaser:hover .ns-teaser__part--image .ns-teaserImage {
  transform: scale(1.1);
}
@media (min-width: 768px) {
  .layout3 .ns-teaser:first-child .ns-teaser__part--image {
    width: 47%;
    aspect-ratio: 1.25;
  }
  @supports not (aspect-ratio: 5 /  4) {
    .layout3 .ns-teaser:first-child .ns-teaser__part--image:before {
      float: left;
      padding-top: 80%;
      content: '';
    }
    .layout3 .ns-teaser:first-child .ns-teaser__part--image:after {
      display: block;
      content: '';
      clear: both;
    }
  }
}
.ns-teaser__part--date {
  margin-bottom: 24px;
}
.ns-teaser__part--date .ns-teaserDate {
  float: left;
  color: #B1B1B1;
  position: relative;
  padding-left: 22px;
}
.ns-teaser__part--date .ns-teaserDate:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-calendar.svg);
  background-color: #B1B1B1;
}
.layout3 .ns-teaser:first-child .ns-teaser__part--date .ns-teaserDate {
  color: #577A63;
}
.layout3 .ns-teaser:first-child .ns-teaser__part--date .ns-teaserDate:before {
  background-color: #577A63;
}
.ns-teaser__part--title {
  margin-bottom: 32px;
}
.layout3 .ns-teaser:first-child .ns-teaser__part--title .ns-teaserTitle {
  font-size: 36px;
  line-height: 1.11111111;
  font-weight: 300;
  color: var(--textColor);
}
@media (max-width: 1023px) {
  .layout3 .ns-teaser:first-child .ns-teaser__part--title .ns-teaserTitle {
    font-size: 24px;
    line-height: 1.33333333;
  }
}
.ns-teaser__part--link {
  margin-top: auto;
}
.ns-teaser__part--link .ns-teaserLink {
  float: left;
  font-weight: bold;
  box-sizing: border-box;
  padding-right: 20px;
  position: relative;
  transition: all 0.4s;
  color: var(--linkColor);
}
.ns-teaser__part--link .ns-teaserLink:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right.svg);
  background-color: var(--linkColor);
  transition: all 0.4s;
}
.ns-teaser:hover .ns-teaser__part--link .ns-teaserLink {
  color: var(--linkColorHover);
}
.ns-teaser:hover .ns-teaser__part--link .ns-teaserLink:before {
  background-color: var(--linkColorHover);
}
@media (min-width: 768px) {
  .layout3 .ns-teaser:first-child .ns-teaser__part--link {
    margin-top: unset;
  }
}
.ns-teaserLoadMore {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ns-teaserLoadMore__button {
  display: inline-block;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  cursor: pointer;
  color: #577A63;
  appearance: none;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #EAEAEA;
  transition: all 0.4s;
  padding: 9px 17px;
}
.ns-teaserLoadMore__button:hover,
.ns-teaserLoadMore__button:focus {
  color: #577A63;
  background-color: #EAEAEA;
}
.timeline {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  display: none;
}
.layout2 .timeline {
  display: block;
}
.timeline__wrapper {
  float: left;
  width: 100%;
  position: relative;
}
.timeline__wrapper:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #000;
  z-index: 1;
}
#edit .timeline__wrapper:before {
  display: none;
}
@media (max-width: 767px) {
  .timeline__wrapper:before {
    display: none;
  }
}
.timeline__content {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 2;
}
.timeline__content::-webkit-scrollbar {
  display: none;
}
#edit .timeline__content {
  overflow: visible;
}
@media (max-width: 767px) {
  .timeline__content {
    overflow: visible;
  }
}
.area--timeline {
  float: left;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  margin-top: calc(var(--js-elementHeight) / 2);
  margin-bottom: calc(var(--js-elementHeight) / 2);
}
#edit .area--timeline {
  flex-wrap: wrap;
  min-height: 100px;
  height: auto;
  margin: 0;
}
@media (max-width: 767px) {
  .area--timeline {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.area--timeline .unit--timeline {
  margin-top: 0;
  margin-bottom: 0;
  width: var(--timelineUnitWidth);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  margin-left: calc((var(--timelineUnitWidth) / 2) * -1);
  transform: translateY(calc(var(--js-elementHeight) / 2));
}
.area--timeline .unit--timeline:before {
  content: '';
  position: absolute;
  left: -10px;
  top: -10px;
  width: 21px;
  height: 21px;
  border-radius: 10000px;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #577A63;
  z-index: 5;
}
.area--timeline .unit--timeline .unit__content {
  max-width: 260px;
  box-sizing: border-box;
  border-left: 2px solid #CBCBCB;
  padding: 30px 12px 0;
  flex-grow: 1;
}
.area--timeline .unit--timeline .unit__body {
  margin-top: -5px;
  margin-bottom: -5px;
}
.area--timeline .unit--timeline .part {
  margin-top: 5px;
  margin-bottom: 5px;
}
.area--timeline .unit--timeline:nth-child(2n) {
  transform: translateY(calc((var(--js-elementHeight) / 2) * -1));
}
.area--timeline .unit--timeline:nth-child(2n):before {
  top: initial;
  bottom: -10px;
}
.area--timeline .unit--timeline:nth-child(2n) .unit__content {
  padding-top: 0;
  padding-bottom: 30px;
}
.area--timeline .unit--timeline:first-child {
  margin-left: 60px;
}
.area--timeline .unit--timeline:last-child {
  width: 260px;
  margin-right: 60px;
}
.area--timeline .unit--timeline.unit--timelineActive:before {
  background-color: #577A63;
}
#edit .area--timeline .unit--timeline {
  width: 50%;
  margin: 20px 0 !important;
  transform: none !important;
}
@media (max-width: 767px) {
  .area--timeline .unit--timeline {
    width: 100%;
    margin: 0 !important;
    transform: none !important;
  }
  .area--timeline .unit--timeline:before {
    bottom: initial !important;
    top: -4px !important;
    left: -4px !important;
    width: 10px;
    height: 10px;
  }
  .area--timeline .unit--timeline .unit__content {
    position: relative;
    padding-top: 16px !important;
    padding-bottom: 40px !important;
  }
  .area--timeline .unit--timeline .unit__content:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 110px;
    height: 1px;
    background-color: #B1B1B1;
    z-index: 2;
  }
  .area--timeline .unit--timeline.unit--timelineActive:after {
    bottom: initial;
    top: -15px;
  }
  .area--timeline .unit--timeline:last-child .unit__content {
    padding-bottom: 0 !important;
  }
}
.timeline__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #577A63;
  cursor: pointer;
}
.timeline__arrow:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  mask-size: auto 14px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}
.timeline__arrow:hover,
.timeline__arrow:focus {
  background-color: #577A63;
}
#edit .timeline__arrow {
  display: none;
}
@media (max-width: 767px) {
  .timeline__arrow {
    display: none;
  }
}
.timeline__arrow--left {
  left: -20px;
}
.timeline__arrow--left:after {
  mask-image: url(/images/chevron-left.svg);
}
.timeline__arrow--right {
  right: -20px;
}
.timeline__arrow--right:after {
  mask-image: url(/images/chevron-right.svg);
}
.ns-sliderWrapper {
  float: left;
  width: 100%;
  height: 100svh;
  max-height: 600px;
  position: relative;
}
.ns-slider {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.ns-slider__section {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.ns-slider__content {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.ns-slider__stage {
  float: left;
  width: 100%;
  height: 100%;
}
.ns-slider__area {
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ns-slide {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 50px 0;
  box-sizing: border-box;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.6s;
}
.ns-slide.is-active {
  opacity: 1;
  z-index: 2;
}
.ns-sliderWrapper--referenceFader .ns-slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
}
.ns-sliderWrapper--referenceFader .ns-slide.is-active {
  z-index: 2;
  opacity: 1;
}
.ns-slide__image {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.ns-slide__image * {
  height: 100%;
}
.ns-slide__image img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.ns-slide__image .placeholder {
  padding-bottom: 0;
}
.ns-slide__image:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 40%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent);
  pointer-events: none;
  z-index: 2;
}
.ns-slide__content {
  float: left;
  width: var(--deskWidth);
  max-width: var(--deskMaxWidth);
  display: flex;
  flex-direction: column;
  gap: 16px;
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.2s;
}
.ns-slide.is-active .ns-slide__content {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.6s;
}
.ns-slider__part {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slide__part--title,
.ns-slide__part--text {
  color: var(--textColor);
  max-width: 530px;
}
.ns-sliderControl {
  float: left;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);
  mask-size: 7px 13px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: #fff;
}
.ns-sliderControl--prev {
  left: 20px;
  mask-image: url(/images/chevron-left.svg);
}
.ns-sliderControl--next {
  right: 20px;
  mask-image: url(/images/chevron-right.svg);
}
.ns-sliderIndex {
  float: left;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 10;
}
@media (max-width: 767px) {
  .ns-sliderIndex {
    bottom: 108px;
  }
}
.ns-sliderIndex__content {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}
.ns-sliderIndex__item {
  float: left;
  width: 10px;
  height: 10px;
  background-color: #EAEAEA;
  border-radius: 10px;
  transition: all 0.4s;
}
.ns-sliderIndex__item.is-active {
  background-color: #577A63;
}
/*# sourceMappingURL=./screen-small.css.map */